import * as prismic from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next'
import { setFullLocale } from './utils/utils'

// let redis
// if (typeof window === 'undefined' && process.env.REDIS_URL) {
//   import('ioredis').then((Redis) => {
//     redis = new Redis.default(process.env.REDIS_URL)
//   })
// }

const endpoint = process.env.PRISMIC_URL

export const createClient = (config = {}) => {
  const client = prismic.createClient(endpoint)
  // cachedRepository: true,
  // cachedRepositoryExpiration: 60 * 15 * 1000, // 15 minutes
  // })

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  })

  return client
}

export const getSingleDocument = async (client, lang, document) => {
  let data
  // const cacheKey = `prismic:${document}:${lang}`

  try {
    // if (typeof window !== 'undefined') {
    return await client.getSingle(document, { lang })
    // }

    // if (!redis) {
    //   return await client.getSingle(document, { lang })
    // }
    //
    // const cachedData = await redis.get(cacheKey)
    // if (cachedData) {
    //   return JSON.parse(cachedData)
    // }
    //
    // data = await client.getSingle(document, { lang })
    // if (data) {
    //   await redis.set(cacheKey, JSON.stringify(data), 'EX', 60 * 15)
    // }
  } catch {
    // if (redis)
    //   if (redis) await redis.set(cacheKey, JSON.stringify(null), 'EX', 60 * 15)
    data = null
  }

  return data
}

export const getPrismicDocuments = async (documents, locale, previewData) => {
  const client = createClient({ previewData })
  const lang = setFullLocale(locale)

  const data = await Promise.all(
    Object.values(documents).map((document) =>
      getSingleDocument(client, lang, document)
    )
  )

  return Object.fromEntries(
    Object.keys(documents).map((key, index) => [key, data[index]])
  )
}
